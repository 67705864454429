import { GetTransectionDataService } from './get-transection-data.service';
import { PracticeTypeService } from './practice-type.service';
import { ActivatedRoute, Routes, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ServiceListService } from './service-list.service';
import { RegisterServiService } from './register-servi.service';
import { SubscribeServiService } from './subscribe-servi.service';
import { Component, OnInit,NgZone } from '@angular/core';
import { map } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  {
  constructor() {} 
}

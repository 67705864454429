import { AuthRegistrationService } from './../auth-registration.service';
import { SubscribeServiService } from './../subscribe-servi.service';
import { RegisterServiService } from './../register-servi.service';
import { ServiceListService } from './../service-list.service';
import { PracticeTypeService } from './../practice-type.service';
import { GetTransectionDataService } from './../get-transection-data.service';
import { ActivatedRoute, Routes, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { Component, OnInit, NgZone } from '@angular/core';
import { map } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';
import { ConfirmedValidator } from './confirmed.validator';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { ThrowStmt } from '@angular/compiler';
declare var $: any;

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent {

  allServiceList: any = [];imageSrc =undefined; finalService: any = []; uniqueId; practiceEmail; practiceName:string=""; practiceContactNumber:string="";
  physicalAddress:string="";serviceData:any=[]; postalAddress:string=""; gender; subscriberdServices: any = []; AES_KEY = new FormData(); signature = new FormData();
  subscribedServices; doctorNumber; practiceType; firstName; lastName; email; userName; subsribedServiceArray: any = []; serviceName;
  subscriptionStatus; subscriptionEndDate;terms = true; serviceEffectiveDate; activationDate; subscriptionDate; multipleServiceBox = true;
  practiceNumber; pracTypeList: any = []; sourceType; restService; practiceTypeCode;passwords:string='';showPassword =false;availableService; sampledService: any = []; Subscription; serviceLevel; showMessage = false;
  showClickDocMessage = false; showClickDocMessageNew = false; private UUID; showEscriptingCert = false; fileData: File = null; previewUrl: any = null; previewCertUrl: any = null;
  showCheck: any = [];registerData;emailtemplate:string=""; error; showSubmit = true; files: any = [];selectmessage="";confirmpassword;
  public barLabel: string = "Password strength:";showPText:boolean = false;
  showCPText:boolean = false;showCheckList: any = []; hideCheckList: any = [];apitoken;submitForm = true;
  successMessage:any=[];failureMessage:any=[];showloader = false;confirmPasswordMessage;
  passwordError='';serviceTypeDoctor =false;dialogmessage :string="";onlinePatientSelect:boolean=false;showtab1=true;showtab3=false;showtab4=false;showtab5=false;showtab2=false;
  readMoreData = null;
  debtmanagement =false;responsiblefirstName;responsiblelastName;responsibleemail;
  slideConfig = {  
    "slidesToShow": 3,  
    "slidesToScroll": 3,  
    "dots": true,  
    "infinite": true  
  };  

  subscriptionArray=[
    {
    imageurl:"../assets/images/Payment_link.jpg",
    title:"Payment Link",
    description:`Getting paid upfront and on time is a cinch! With <b>POS+ and Link2Pay, </b>
    your practice can now accept cash, card as well as contactless payments. 
    Our <b>best-in-class security </b>keeps your transactions safe, no matter how your patients choose to pay.
    including POS+, online and contactless payments - credit card, SnapScan, Maestro, EFT and more.
    <b>Ready to take control of your practice? </b>`,
    code:"PAYMENT_LINK"
  },{
    imageurl:"../assets/images/Medme.jpg",
    title:"Online Calendar",
    description:`Patient-friendly onboarding: <b>no more printed paper forms </b> and clipboards. 
    Our digital patient intake quashes the busy work and makes it easy for patients 
    to register with your practice remotely. With <b>automated reminders and reschedules, 
    patients manage their appointments online </b>minimising interruptions in care.`,
    code:"ONLINE_CALENDAR"
  },{
    imageurl:"../assets/images/E-script.jpg",
    title:"E Scripting",
    description:`Source type is sourceType not MEDEDI DLorem Ipsum is simply dummy text of the printing and
    typesetting industry. Lorem Ipsum has been the
    industry's standard dummy text ever since the 1500s,
    when an unknown printer took a galley of type and
    scrambled.`,
    code:"E-SCRIPTING"
  },{
    imageurl:"../assets/images/VConsultation.jpg",
    title:"Video Consultation",
    description:`CLICKDOC ensures that no patient goes without care by offering <b>greater choice </b>
    and <b>easier access to quality medical healthcare</b> with secure video consults. Treat 
    patients from afar - with a virtual waiting area, document sharing and more. 
    Patients are spared queues and can connect securely and conveniently in comfort.
    Expand your practice scope and <b>attract new patients with a streamlined schedule</b>, 
    remote treatment options and extended hours.`,
    code:"VIDEO_CONSULTATION"
  },{
    imageurl:"../assets/images/LabResults.jpg",
    title:"Lab Result",
    description:`Source type is sourceType not MEDEDI DLorem Ipsum is simply dummy text of the printing and
    typesetting industry. Lorem Ipsum has been the
    industry's standard dummy text ever since the 1500s,
    when an unknown printer took a galley of type and
    scrambled.`,
    code:"LAB_RESULTS"
  },{
    imageurl:"../assets/images/Recomed.jpg",
    title:"Recomed",
    description:`Get the antidote for no-shows and stop the endless back and forth communication with appointment bookings. 
    RecoMed integrates with your practice diary to offer online bookings to your patients at a time and date that suits them. 
    Your practice receives the appointment requests in the MEDEDI practice diary and can approve or decline depending on the availability of the doctors in your practice. 
    Once an appointment has been approved by your practice team, the patient receives a confirmation via email and SMS, followed by a reminder one day before as well as on the day of their appointment. 
    <b>The time saved by this process can then allow your team to spend time on what matters - patient care!</b>`,
    code:"RECOMED"
  },{
    imageurl:"../assets/images/Payment_link.jpg",
    title:"Early Stage Collection",
    description:`Early Stage Collection? </b>`,
    code:"DEBT_MANAGEMENT"
  }
];
  constructor(private fb: FormBuilder,private authapi:AuthRegistrationService, private ngZone: NgZone, private router: Router, private getData: GetTransectionDataService, private changeDetector: ChangeDetectorRef, private pracList: PracticeTypeService, private _activatedROute: ActivatedRoute, private subsribeservice: SubscribeServiService, private servicelist: ServiceListService, private registerUser: RegisterServiService) {
    
      
      this.viewInit();
      
    this.showTab1();
    
  }
  
  hide(){
    //document.getElementById("container-div").style.opacity = "0.5";
    document.getElementById("loader").style.display = "none";
    document.getElementById("div-loader").style.display = "none";
  }
  show(){
    //console.log("ato b"+ atob(JSON.parse(this.idtoken)));
    
    //document.getElementById("container-div").style.opacity = "1";
    document.getElementById("loader").style.display = "block";
    document.getElementById("div-loader").style.display = "block";
  }
  
  SearchData(confirmpassword,passType) {
      //alert(confirmpassword)
      if(passType =='conpass'){
          if(this.passwords == confirmpassword){
            this.imageSrc = '../assets/images/right.png';
            this.confirmPasswordMessage ="Password match!";
            this.submitForm = true;
        }else{
            this.imageSrc = '../assets/images/wrong.png';
            this.confirmPasswordMessage = "Password do not match";
        }
      }else{
          if(this.confirmpassword == confirmpassword){
            this.imageSrc = '../assets/images/right.png';
            this.confirmPasswordMessage ="Password match!";
            this.submitForm = true;
        }else{
            this.imageSrc = '../assets/images/wrong.png';
            this.confirmPasswordMessage = "Password do not match";
        }
      }
   
}
  viewInit() {

    this.allServiceList = [];this.Subscription = ""; this.sampledService = [];
    this.pracTypeList = [];this.subscriberdServices = [];this.error = "";
    this.selectmessage="";
   
    
    this.pracList.getListService().pipe(map((res: any) => {
      res.forEach(element => {
        this.pracTypeList.push(element.typename);
      });

      this.pracTypeList.sort();
    })).subscribe((data) =>{ 

    });


    /**
     * Parameters to get from the URL
     */
    this._activatedROute.queryParamMap.subscribe(queryParams => {
      /**
       * Code for UUID
       */
      this.UUID = queryParams.get("uuid");
      this.apitoken = queryParams.get("token");
      //alert("this api toke is " +this.apitoken);
      if (this.UUID != null || this.UUID != "" || this.UUID != undefined) {
        this.getData.getService(this.UUID,this.apitoken).pipe(map((res: any) => {
          
          this.practiceNumber = res["practiceNumber"];
          this.uniqueId = res["uniqueId"];
          this.sourceType = res["sourceName"];
          this.firstName = res["firstName"];
          this.lastName = res["lastName"];
          this.email = res["email"];
          this.userName = res["username"];
          this.doctorNumber = res["doctorNumber"];
          this.practiceType = res["practiceType"];
          this.Subscription = res["subscriptionInterest"];
          this.physicalAddress = res["practicePhysicalAddress"];
          this.postalAddress = res["practicePostalAddress"];
          this.gender = res["gender"];
          this.practiceContactNumber = res["practiceContactNumber"];
          this.practiceName = res["practiceName"];
          this.practiceEmail = res["practiceEmail"];
          
          this.subsribeservice.getSubscriptionService(this.practiceNumber,this.doctorNumber,this.apitoken).pipe(map((subData: any) => {
            this.subscribedServices = subData;

            this.subscriptionEndDate = moment(this.serviceEffectiveDate).add(3, 'months').format('YYYY-MM');
            this.subscriptionEndDate = this.subscriptionEndDate + "-" + moment(this.subscriptionEndDate).daysInMonth();
            for (var i = 0; i < subData.length; i++) {
              this.subsribedServiceArray.push(subData[i].service);
            }
            this.servicelist.getDataService(this.apitoken).pipe(map((subData: any) => {
              this.changeDetector.detectChanges()
              this.availableService = subData;
              if(this.Subscription != undefined){
                for (var i = 0; i < this.Subscription.length; i++) {
                  this.Subscription[i] = this.Subscription[i].trim();
                  for (var j = 0; j < this.availableService.length; j++) {
                    if (this.availableService[j].code == this.Subscription[i]) {
                      this.sampledService.push(this.availableService[j].description);
                    }
                  }
                }
              }
              
             
              for (var i = 0; i < subData.length; i++) {
                this.allServiceList.push(subData[i].description);
              }

              this.finalService = [];
              for (var i = 0; i < this.subsribedServiceArray.length; i++) {
                if (this.subsribedServiceArray[i].code == "CLICKDOC") {
                  this.showClickDocMessage = true;
                }
                if (this.subsribedServiceArray[i].code == "VIDEO_CONSULTATION") {
                  this.showClickDocMessageNew = true;
                  
                }
                this.finalService.push({ "code": this.subsribedServiceArray[i].code, "description": this.subsribedServiceArray[i].description, "serviceLevel": this.subsribedServiceArray[i].serviceLevel })
              }
              const finalArr = subData.filter(({ code }) =>
                !this.finalService.some(exclude => exclude.code === code)
              );
              this.restService = finalArr;
              if(this.Subscription != undefined){
                
                for (var i = 0; i < this.Subscription.length; i++) {
                  this.showCheck.push({ "code": this.Subscription[i], "showCheck": true,"disabled": false});
                }
              }
              

              const finalArray = this.restService.filter(({ code }) =>
                !this.showCheck.some(exclude => exclude.code === code)
              );
             
              this.hideCheckList = finalArray;

            //  alert(this.hideCheckList.length + " this.hideCheckList");
              const finalArray2 = this.restService.filter(({ code }) =>
                this.showCheck.some(include => include.code === code)
                
              );
              this.showCheckList = finalArray2;
              for (var i = 0; i < this.showCheckList.length; i++) {
                if (this.showCheckList[i].code == "VIDEO_CONSULTATION") {
                 this.showPassword = true;
                 this.submitForm = false;
                }
               
                 // this.showPassword = true;
                  //this.submitForm = false;
                  if(this.sourceType == "MEDIDI"){
                 
                    
                  }
                 
                  if (this.showCheckList[i].code == "DEBT_MANAGEMENT") {
                    this.debtmanagement = true;
                   
                   }
                if(this.showCheckList[i].code == "ONLINE_CALENDAR"){
                  this.onlinePatientSelect = true;
                }
              }
              for (var i = 0; i < this.hideCheckList.length; i++) {
                if(this.hideCheckList[i].code == "E-SCRIPTING" || this.hideCheckList[i].code == "LAB_RESULTS"){
               //   this.hideCheckList.pop(i);
                //  this.changeDetector.detectChanges();
                 }
              }
              if (this.hideCheckList.length <= 1 ) {
                for (var i = 0; i < this.hideCheckList.length; i++) {
                  if (this.hideCheckList[i].code == "CLICKDOC") {
                    //document.getElementById("submitbtn").disabled = true;
                    document.getElementById("submitbtn").setAttribute('disabled','true');
                    this.changeDetector.detectChanges();
                  }
                 
                }
              }
              if(this.sourceType == "MEDIDI"){
               // document.getElementById("E-SCRIPTING").style.display='none';
              //  document.getElementById("LAB_RESULTS").style.display='none';
                
              }
            })).subscribe((data) => {

            });
          }))

            .subscribe((data) => {

            });
          if (this.Subscription != null) {
            this.changeDetector.detectChanges();
            this.Subscription = this.Subscription.split(",")
            this.showMessage = true;


          }

          if (this.sourceType == "ADMIN") {
            this.showEscriptingCert = true;
          } else if (this.sourceType != "WEB") {
            if (this.sourceType != null) {
              if (this.doctorNumber == null || this.practiceNumber == null || this.userName == null || this.uniqueId == null) {
                alert("Access not authorized.");
              }
            }
          }

          this.pracList.getListService().pipe(map((res: any) => {
            res.forEach(element => {
              if (this.practiceType == element.practype) {
                //alert(element.practype);
                this.practiceType = element.typename;
                this.practiceTypeCode = element.practype;
              }

            });
          })).subscribe((data) => {

          });


        }))
          .subscribe((data) => {
          
          }, (error) => {
            this.ngZone.run(() => { this.router.navigateByUrl('/error'); });
          });
      } else {
        alert('UUID is not valid');
      }
      this.ngZone.run(() => {


      });
    });
  }
  toggleEditable(event, data) {
    //alert(JSON.stringify(data));
    this.serviceTypeDoctor = false;
    if(data.code == "DEBT_MANAGEMENT"){
      if(event.target.checked){
        this.debtmanagement =true;
      }else{
        this.debtmanagement=false;
      }
  }
    if (event.target.checked) {
      
      document.getElementById(event.target.id).parentElement.parentElement.classList.remove("text-primary");
      document.getElementById(event.target.id).parentElement.parentElement.classList.add("activegreen");
      document.getElementById(event.target.id).setAttribute('checked', 'true');
      if(data.code =="VIDEO_CONSULTATION"){
        this.showPassword = true;
        this.submitForm = false;
        
      }
      if(data.code == "ONLINE_CALENDAR"){

        this.onlinePatientSelect = true;
      }
    }
    if (!event.target.checked) {
      document.getElementById(event.target.id).parentElement.parentElement.classList.remove("activegreen");
      document.getElementById(event.target.id).parentElement.parentElement.classList.add("text-primary");
      document.getElementById(event.target.id).setAttribute('checked', 'false');
      if(data.code =="VIDEO_CONSULTATION"){
        this.showPassword = false;
        this.submitForm = true;
        this.passwordError = "";
        this.passwords = "";
        this.confirmpassword = "";
      }
      if(data.code == "ONLINE_CALENDAR"){
        this.onlinePatientSelect = false;
      }
    }
    this.changeDetector.detectChanges();
  }
  passPracticeType(data) {
    this.pracList.getListService().pipe(map((res: any) => {
      res.forEach(element => {
        if (data == element.typename) {
          //alert(element.practype);
          this.practiceTypeCode = element.practype;
        }

      });
    })).subscribe((data) => {

    });

  }
  showInfo(data) {
    this.multipleServiceBox = false;
    this.serviceName = data.service["description"];
    this.subscriptionStatus = data["subscriptionStatus"];
    this.serviceLevel = data.service["serviceLevel"];

    this.userName = data.username;
    if (data.service["dateEffective"] != null || data.service["dateEffective"] != undefined) {
      this.serviceEffectiveDate = data.service["dateEffective"].split(' ')[0];
    }
    if (data.subscriptionDate != null || data.subscriptionDate != undefined) {
      this.subscriptionDate = data.subscriptionDate.split(' ')[0];
    }
    if (data.activationDate != null || data.activationDate != undefined) {
      this.activationDate = data.activationDate.split(' ')[0];
    }


  }
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  public fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }
  previewCert() {
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewCertUrl = reader.result;
    }
  }

  onCertFileChange(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.previewCert();
  }

  signatureFileChange(files: any[]) {
    if (files && files.length > 0) {
      let file = files[0];
      this.signature.append('file', file);
    }
  }
  showPasswordText(password){
      if(password == "cpassword"){
          this.showCPText =  !this.showCPText;
          
      }else if(password == "password"){
          this.showPText = !this.showPText;
      }
  }


  registerUserData() {
   
    const formDataCert = new FormData();
    
    for (var i = 0; i < this.files.length; i++) {
      formDataCert.append("file[]", this.files[i]);
    }
    let serviceData1 = [];this.serviceData = [];
    for (var i = 0; i < this.restService.length; i++) {
      if(this.sourceType == "MEDEDI"){
        if (this.restService[i].code != "E-SCRIPTING" && this.restService[i].code != "LAB_RESULTS" ) {
          // alert(document.getElementById(this.restService[i].code).getAttribute('checked'))
          if (document.getElementById(this.restService[i].code).getAttribute('checked') == "true") {
            console.log("service level "+this.restService[i].serviceLevel);
            if(this.restService[i].serviceLevel == "DOCTOR"){
              this.serviceTypeDoctor = true;
            }
            serviceData1.push(this.restService[i].description)
  
            this.serviceData.push(this.restService[i].description);
            this.changeDetector.detectChanges();
          }
        }
      }else{
        if (this.restService[i].code != "CLICKDOC") {
          // alert(document.getElementById(this.restService[i].code).getAttribute('checked'))
          if (document.getElementById(this.restService[i].code).getAttribute('checked') == "true") {
            console.log("service level "+this.restService[i].serviceLevel);
            if(this.restService[i].serviceLevel == "DOCTOR"){
              this.serviceTypeDoctor = true;
            }
            serviceData1.push(this.restService[i].description)
  
            this.serviceData.push(this.restService[i].description);
            this.changeDetector.detectChanges();
          }
        }
      }
      
    }

    let serviceData = JSON.stringify(serviceData1);
    console.log("Service Data" + serviceData);
   
      
    
    if(this.uniqueId =="" || this.uniqueId == undefined || this.practiceNumber == '0' || this.practiceNumber == ""){
     /// this.dialogmessage = "A valid Reference ID, Practice Number is required for subscriptions. Please contact CGM support on 086 1633334 or send and email to subscription.za@cgm.com for further assistance.";
     // this.emailtemplate = "Dear CGM,%0D%0A%0D%0AReference ID: "+this.uniqueId+"%0D%0A"+"Application Name: "+this.sourceType+"%0D%0A%0D%0APlease can you provide assistance with the registration for the following subscriptions:%0D%0A"+serviceData+"%0D%0A%0D%0APRACTICE INFORMATION%0D%0A    Practice Number: "+ this.practiceNumber +"%0D%0A    Practice Type: " +this.practiceTypeCode+"%0D%0A    Practice Name: "+ this.practiceName+"%0D%0A    Practice Email: "+ this.practiceEmail +" %0D%0A    Practice Contact Number: "+ this.practiceContactNumber+"%0D%0A    Physical Address: "+ this.physicalAddress+"%0D%0A    Postal Address: "+ this.postalAddress+"%0D%0A%0D%0ADOCTOR INFORMATION%0D%0A    Doctor Number: "+ this.doctorNumber +"%0D%0A    Doctor Name: "+ this.firstName+"%0D%0A    Doctor Surname: "+this.lastName+"%0D%0A    Email Address: "+this.email+"%0D%0A    Username: "+this.userName+"%0D%0A%0D%0AKind Regards";
     // $("#nodocotorpractice").modal()
      //return;
      
    }
    
    if (this.userName == "" || this.userName == null || this.userName == undefined) {
      this.userName = this.practiceEmail;
    }
    if(this.submitForm == false){

        this.passwordError ="Please enter password to proceed.";
        //document.getElementById("usercredenlabel").scrollIntoView();
        this.showTab3();
        return;
        
    }else if(this.submitForm == true){
        if(this.showPassword){
            if(this.passwords != this.confirmpassword){
             this.passwordError = "Password and confirm password do not match.";
                this.showTab3();
                return;   
            }
            else if (this.passwords.length < 9) {
                this.passwordError = "Password should be 10 characters";
                this.showTab3();
                return;
            } else if (this.passwords.length > 50) {
                this.passwordError = "Password should not be more than 50 characters";
                this.showTab3();
                return;
            } else if (this.passwords.search(/\d/) == -1) {
                this.passwordError = "Please enter atleast one number";
                this.showTab3();
                return
            } else if (this.passwords.search(/[a-zA-Z]/) == -1) {
                this.passwordError = "Please enter atleast one character";
                this.showTab3();
                return
            } else if (this.passwords.search(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/) == -1) {
                this.passwordError = "Please enter atleast one special character";
                this.showTab3();
                return
            }
        this.passwordError = '';
        }
        
    }
    const formData = new FormData();
    formData.append('file', this.previewUrl);
    let byteNumbersCert;
    if (this.previewUrl != null) {
      this.previewUrl = this.previewUrl.toString();
      const byteCharactersCert = atob(this.previewCertUrl.replace(/^data:application\/(x-pkcs12);base64,/, ''));
       byteNumbersCert = new Array(byteCharactersCert.length);
      for (let i = 0; i < byteCharactersCert.length; i++) {
        byteNumbersCert[i] = byteCharactersCert.charCodeAt(i);
      }
    }else{
      byteNumbersCert = null;
    }
    let byteNumbers;
    if (this.previewCertUrl != null ) {
      const byteCharacters = atob(this.previewUrl.replace(/^data:image\/(png);base64,/, ''));
       byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
    }else{
      byteNumbers = null;
    }

  
    this.subscriberdServices = [];
    for (var i = 0; i < this.restService.length; i++) {
      if(this.sourceType =="MEDEDI"){
        if (this.restService[i].code != "LAB_RESULTS" && this.restService[i].code != "E-SCRIPTING") {
          // alert(document.getElementById(this.restService[i].code).getAttribute('checked'))
          if (document.getElementById(this.restService[i].code).getAttribute('checked') == "true") {
            this.subscriberdServices.push({ "code": this.restService[i].code })
          }
        }
      }else{
        if (this.restService[i].code != "CLICKDOC") {
          // alert(document.getElementById(this.restService[i].code).getAttribute('checked'))
          if (document.getElementById(this.restService[i].code).getAttribute('checked') == "true") {
            this.subscriberdServices.push({ "code": this.restService[i].code })
          }
        }
      }
     
    }
    if(this.subscriberdServices.length==0){
      //alert(");
      this.selectmessage = "Please select service to subscribe";
      this.showTab1();
      return;
    }else{
      this.selectmessage= "";
    }

    
    
    this.registerData = {
        "uniqueId": this.uniqueId,
        "practiceEmail": this.practiceEmail,
        "PracticeName": this.practiceName,
        "practiceContactNumber": this.practiceContactNumber,
        "physicalAddress": this.physicalAddress,
        "postalAddress": this.postalAddress,
        "practiceNumber": this.practiceNumber,
        "doctorNumber": this.doctorNumber,
        "practiceType": this.practiceTypeCode,
        "sourceName": this.sourceType,
        "email": this.email,
        "username": this.userName,
        "password": this.passwords,
        "title": "",
        "firstname": this.firstName,
        "lastName": this.lastName,
        "profiletype": "",
        "preferredlang": "",
        "gender": this.gender,
        "services": this.subscriberdServices,
        "aesKey": byteNumbersCert,
        "signature": byteNumbers
      };
      if(this.debtmanagement){
        this.registerData.responsiblePersonName = this.responsiblefirstName;
        this.registerData.responsiblePersonSurname = this.responsiblelastName;
        this.registerData.responsiblePersonEmail = this.responsibleemail;
      }
  }
  closeModal(){
    let check2 = document.getElementById("agreement") as HTMLInputElement
   check2.checked = false; 
   //this.terms = false;
   $("#submitModal").modal('hide');

  }
  ngAfterViewInit(){
    this.showTab1();
  }
showTab1(){

    $('#tab1').show();
    $('#tab3').hide();
    $('#tab4').hide();
    $('#tab5').hide();
    $('#tab2').hide();
    this.showtab2=false;
    this.showtab1=true;
    this.showtab3=false;
    this.showtab4=false;
    this.showtab5=false;
    
}

showTab2(){

  $('#tab1').hide();
  $('#tab3').hide();
  $('#tab4').hide();
  $('#tab5').hide();
  $('#tab2').show();
  this.showtab1=false;
  this.showtab3=false;
  this.showtab4=false;
  this.showtab5=false;
  this.showtab2=true;
  
}

showTab3or4(){
  if(this.debtmanagement){
   this.showTab2();
  }else{
  this.showTab4();
  }
}
showTab2or3(){
  if(this.debtmanagement){
   this.showTab2();
  }else{
  this.showTab3();
  }
}
showTab3(){
    $('#tab3').show();
    $('#tab1').hide();
    $('#tab4').hide();
    $('#tab5').hide();
    this.showtab1=false;
    this.showtab3=true;
    this.showtab4=false;
    this.showtab5=false;
    $('#tab2').hide();
    this.showtab2=false;
}
showTab4(){
  if(this.debtmanagement){
    if(this.responsibleemail == "" || this.responsiblefirstName == "" || this.responsiblelastName ==""){
      alert("Please enter all the required field.")
    }
  }
    $('#tab4').show();
    $('#tab3').hide();
    $('#tab1').hide();
    $('#tab5').hide();
    this.showtab1=false;
    this.showtab3=false;
    this.showtab4=true;
    this.showtab5=false;
    $('#tab2').hide();
    this.showtab2=false;
}
showTab5(){
    $('#tab5').show();
    $('#tab3').hide();
    $('#tab4').hide();
    $('#tab1').hide();
    this.showtab1=false;
    this.showtab3=false;
    this.showtab4=false;
    this.showtab5=true;
    $('#tab2').hide();
    this.showtab2=false;
    setTimeout(()=>{                           // <<<---using ()=> syntax
        this.registerUserData();
    }, 1000);
   
}
 
openModalCurrent(){
  $("#myModal1").modal('show');
}
showModal():void {
  $("#myModal").modal('show');
}
showReadMoreModal(code):void{
    for(var i=0;i<this.subscriptionArray.length;i++){
        if(this.subscriptionArray[i].code == code){
            this.readMoreData = this.subscriptionArray[i];
        }
    }
  $("#readMore").modal('show');
}
sendModal(): void {
  //do something here
  this.hideModal();
}
hideModal():void {
  document.getElementById('close-modal').click();
}
hideModalReadMore(){
  document.getElementById('close-modal-readmore').click();
}

  continueSubmit(){
    if(this.serviceTypeDoctor){
      if(this.doctorNumber == "" || this.doctorNumber == 0 || this.doctorNumber == undefined){
        this.dialogmessage = "A valid Medical Practitioner Number is required for subscriptions. Please contact CGM support on 086 1633334 or send an email to subscription.za@cgm.com for further assistance.";
        this.emailtemplate = "Dear CGM,%0D%0A%0D%0AReference ID: "+this.uniqueId+"%0D%0A"+"Application Name: "+this.sourceType+"%0D%0A%0D%0APlease can you provide assistance with the registration for the subscriptions. %0D%0APRACTICE INFORMATION%0D%0A    Practice Number: "+ this.practiceNumber +"%0D%0A    Practice Type: " +this.practiceTypeCode+"%0D%0A    Practice Name: "+ this.practiceName+"%0D%0A    Practice Email: "+ this.practiceEmail +" %0D%0A    Practice Contact Number: "+ this.practiceContactNumber+"%0D%0A    Physical Address: "+ this.physicalAddress+"%0D%0A    Postal Address: "+ this.postalAddress+"%0D%0A%0D%0ADOCTOR INFORMATION%0D%0A    Doctor Number: "+ this.doctorNumber +"%0D%0A    Doctor Name: "+ this.firstName+"%0D%0A    Doctor Surname: "+this.lastName+"%0D%0A    Email Address: "+this.email+"%0D%0A    Username: "+this.userName+"%0D%0A%0D%0AKind Regards";
        $("#nodocotorpractice").modal()
        return;
      }
    }

    if(this.practiceNumber ==""){
      alert("Please enter practice number");
      this.dialogmessage = "A valid Practice Number is required for subscriptions. Please provide the required information. Should you need any assistance please contact CGM support on 086 1633334 or send an email to subscription.za@cgm.com for further assistance.";
      this.emailtemplate = "Dear CGM,%0D%0A%0D%0AReference ID: "+this.uniqueId+"%0D%0A"+"Application Name: "+this.sourceType+"%0D%0A%0D%0APlease can you provide assistance with the registration for the subscriptions. %0APRACTICE INFORMATION%0D%0A    Practice Number: "+ this.practiceNumber +"%0D%0A    Practice Type: " +this.practiceTypeCode+"%0D%0A    Practice Name: "+ this.practiceName+"%0D%0A    Practice Email: "+ this.practiceEmail +" %0D%0A    Practice Contact Number: "+ this.practiceContactNumber+"%0D%0A    Physical Address: "+ this.physicalAddress+"%0D%0A    Postal Address: "+ this.postalAddress+"%0D%0A%0D%0ADOCTOR INFORMATION%0D%0A    Doctor Number: "+ this.doctorNumber +"%0D%0A    Doctor Name: "+ this.firstName+"%0D%0A    Doctor Surname: "+this.lastName+"%0D%0A    Email Address: "+this.email+"%0D%0A    Username: "+this.userName+"%0D%0A%0D%0AKind Regards";
      $("#nodocotorpractice").modal();
      //return;
      return;
    }else if(this.practiceName == ""){
      this.dialogmessage = "A valid Practice Name is required for subscriptions. Please provide the required information. Should you need any assistance please contact CGM support on 086 1633334 or send an email to subscription.za@cgm.com for further assistance.";
      this.emailtemplate = "Dear CGM,%0D%0A%0D%0AReference ID: "+this.uniqueId+"%0D%0A"+"Application Name: "+this.sourceType+"%0D%0A%0D%0APlease can you provide assistance with the registration for the subscriptions. %0APRACTICE INFORMATION%0D%0A    Practice Number: "+ this.practiceNumber +"%0D%0A    Practice Type: " +this.practiceTypeCode+"%0D%0A    Practice Name: "+ this.practiceName+"%0D%0A    Practice Email: "+ this.practiceEmail +" %0D%0A    Practice Contact Number: "+ this.practiceContactNumber+"%0D%0A    Physical Address: "+ this.physicalAddress+"%0D%0A    Postal Address: "+ this.postalAddress+"%0D%0A%0D%0ADOCTOR INFORMATION%0D%0A    Doctor Number: "+ this.doctorNumber +"%0D%0A    Doctor Name: "+ this.firstName+"%0D%0A    Doctor Surname: "+this.lastName+"%0D%0A    Email Address: "+this.email+"%0D%0A    Username: "+this.userName+"%0D%0A%0D%0AKind Regards";
      $("#nodocotorpractice").modal();
      return;
    }else if(this.practiceEmail==""){
      this.dialogmessage = "A valid Practice Email is required for subscriptions. Please provide the required information. Should you need any assistance please contact CGM support on 086 1633334 or send an email to subscription.za@cgm.com for further assistance.";
      this.emailtemplate = "Dear CGM,%0D%0A%0D%0AReference ID: "+this.uniqueId+"%0D%0A"+"Application Name: "+this.sourceType+"%0D%0A%0D%0APlease can you provide assistance with the registration for the subscriptions. %0APRACTICE INFORMATION%0D%0A    Practice Number: "+ this.practiceNumber +"%0D%0A    Practice Type: " +this.practiceTypeCode+"%0D%0A    Practice Name: "+ this.practiceName+"%0D%0A    Practice Email: "+ this.practiceEmail +" %0D%0A    Practice Contact Number: "+ this.practiceContactNumber+"%0D%0A    Physical Address: "+ this.physicalAddress+"%0D%0A    Postal Address: "+ this.postalAddress+"%0D%0A%0D%0ADOCTOR INFORMATION%0D%0A    Doctor Number: "+ this.doctorNumber +"%0D%0A    Doctor Name: "+ this.firstName+"%0D%0A    Doctor Surname: "+this.lastName+"%0D%0A    Email Address: "+this.email+"%0D%0A    Username: "+this.userName+"%0D%0A%0D%0AKind Regards";
      $("#nodocotorpractice").modal();
      return;
    }else if(this.practiceContactNumber ==""){
      //alert("Please enter valid practice contact number.");
      this.dialogmessage = "A valid Practice Contact Number is required for subscriptions. Please provide the required information. Should you need any assistance please contact CGM support on 086 1633334 or send an email to subscription.za@cgm.com for further assistance.";
      this.emailtemplate = "Dear CGM,%0D%0A%0D%0AReference ID: "+this.uniqueId+"%0D%0A"+"Application Name: "+this.sourceType+"%0D%0A%0D%0APlease can you provide assistance with the registration for the subscriptions. %0APRACTICE INFORMATION%0D%0A    Practice Number: "+ this.practiceNumber +"%0D%0A    Practice Type: " +this.practiceTypeCode+"%0D%0A    Practice Name: "+ this.practiceName+"%0D%0A    Practice Email: "+ this.practiceEmail +" %0D%0A    Practice Contact Number: "+ this.practiceContactNumber+"%0D%0A    Physical Address: "+ this.physicalAddress+"%0D%0A    Postal Address: "+ this.postalAddress+"%0D%0A%0D%0ADOCTOR INFORMATION%0D%0A    Doctor Number: "+ this.doctorNumber +"%0D%0A    Doctor Name: "+ this.firstName+"%0D%0A    Doctor Surname: "+this.lastName+"%0D%0A    Email Address: "+this.email+"%0D%0A    Username: "+this.userName+"%0D%0A%0D%0AKind Regards";
      $("#nodocotorpractice").modal();
      return;
    }else if(this.physicalAddress ==""){
      //alert("Please enter valid physical address");
      this.dialogmessage = "A valid Physical Address is required for subscriptions. Please provide the required information. Should you need any assistance please contact CGM support on 086 1633334 or send an email to subscription.za@cgm.com for further assistance.";
      this.emailtemplate = "Dear CGM,%0D%0A%0D%0AReference ID: "+this.uniqueId+"%0D%0A"+"Application Name: "+this.sourceType+"%0D%0A%0D%0APlease can you provide assistance with the registration for the subscriptions. %0APRACTICE INFORMATION%0D%0A    Practice Number: "+ this.practiceNumber +"%0D%0A    Practice Type: " +this.practiceTypeCode+"%0D%0A    Practice Name: "+ this.practiceName+"%0D%0A    Practice Email: "+ this.practiceEmail +" %0D%0A    Practice Contact Number: "+ this.practiceContactNumber+"%0D%0A    Physical Address: "+ this.physicalAddress+"%0D%0A    Postal Address: "+ this.postalAddress+"%0D%0A%0D%0ADOCTOR INFORMATION%0D%0A    Doctor Number: "+ this.doctorNumber +"%0D%0A    Doctor Name: "+ this.firstName+"%0D%0A    Doctor Surname: "+this.lastName+"%0D%0A    Email Address: "+this.email+"%0D%0A    Username: "+this.userName+"%0D%0A%0D%0AKind Regards";
      $("#nodocotorpractice").modal();
      return;
    }else if(this.postalAddress == ""){
      this.dialogmessage = "A valid Postal Address is required for subscriptions. Please provide the required information. Should you need any assistance please contact CGM support on 086 1633334 or send an email to subscription.za@cgm.com for further assistance.";
      this.emailtemplate = "Dear CGM,%0D%0A%0D%0AReference ID: "+this.uniqueId+"%0D%0A"+"Application Name: "+this.sourceType+"%0D%0A%0D%0APlease can you provide assistance with the registration for the subscriptions. %0APRACTICE INFORMATION%0D%0A    Practice Number: "+ this.practiceNumber +"%0D%0A    Practice Type: " +this.practiceTypeCode+"%0D%0A    Practice Name: "+ this.practiceName+"%0D%0A    Practice Email: "+ this.practiceEmail +" %0D%0A    Practice Contact Number: "+ this.practiceContactNumber+"%0D%0A    Physical Address: "+ this.physicalAddress+"%0D%0A    Postal Address: "+ this.postalAddress+"%0D%0A%0D%0ADOCTOR INFORMATION%0D%0A    Doctor Number: "+ this.doctorNumber +"%0D%0A    Doctor Name: "+ this.firstName+"%0D%0A    Doctor Surname: "+this.lastName+"%0D%0A    Email Address: "+this.email+"%0D%0A    Username: "+this.userName+"%0D%0A%0D%0AKind Regards";
      $("#nodocotorpractice").modal();
      // alert("Please enter valid postal address");
      return;
    }
    let check =document.getElementById("agreement") as HTMLInputElement;
    if(check.checked){
   
   let check2 = document.getElementById("agreement") as HTMLInputElement
   check2.checked = false; 
   
   let data = this.registerData;
    this.successMessage=[];this.failureMessage=[];
   // this.show();
   //  document.getElementById("formcontainer").scrollIntoView();
    this.registerUser.registerUserService(data,this.apitoken).pipe(map((res: any) => {
     // console.log(res);
     
     //this.ngOnInit();
     console.log(JSON.stringify(res));
      for (var i = 0; i < res.serviceResponse.length; i++) {
        if(res.serviceResponse[i].status == "SUCCESS"){
          for(var j=0;j<this.availableService.length;j++){
            if (this.availableService[j].code == res.serviceResponse[i].code) {
              this.successMessage.push(this.availableService[j].description);
              //this.changeDetector.detectChanges();
            }
          }
          this.showTab1();
          //console.log("message : " + JSON.parse(res.serviceResponse[i].message).status);
          
         // this.changeDetector.detectChanges();
        }
        if (res.serviceResponse[i].status == "FAILED") {
          
            for(var j=0;j<this.availableService.length;j++){
              if (this.availableService[j].code == res.serviceResponse[i].code) {
                this.failureMessage.push({
                  "description":this.availableService[j].description,
                  "reason":JSON.parse(res.serviceResponse[i].message).responseMessages
                });
                //this.changeDetector.detectChanges();
              }
            }
            this.showTab1();
          
        }
      }
      
    })).subscribe((data) => {
      this.viewInit();
      this.registerData ={};
     // $('#loader').hide();
     //this.hide();
      this.showloader = false;
     
  });;
  this.terms =true;
    }else{
     // alert("Please accept the agreement");
     this.terms =false;
      return;
    }
  }
}

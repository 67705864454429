import { GeneratePracticeidService } from './generate-practiceid.service';
import { GetPracticeidService } from './get-practiceid.service';
import { UploadCertificateService } from './upload-certificate.service';
import { TokenService } from './token/token.service';
import { environment } from './../environments/environment';
import { DoctorsearchService } from './adminservice/doctorsearch.service';
import { PracticesearchService } from './adminservice/practicesearch.service';
import { AdminpanelComponent } from './adminpanel/adminpanel.component';
import { GetTransectionDataService } from './get-transection-data.service';
import { ActivatedRoute } from '@angular/router';
import { ServiceListService } from './service-list.service';
import { RegisterServiService } from './register-servi.service';
import { SubscribeServiService } from './subscribe-servi.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from './error-page/error-page.component';
import { RegistrationComponent } from './registration/registration.component';
import { LoaderComponent } from './loader/loader.component'
import { CustomHttpInterceptorService } from './custom-http-interceptor.service';
import * as jwt_decode from 'jwt-decode';
import {PassowordStrengthBarComponent } from './password-strength-bar/password-strength-bar.component'
import { ConfirmedValidator } from './registration/confirmed.validator';



const routes: Routes = [
  { path: 'error', component: ErrorPageComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'admin', component: AdminpanelComponent }
];
@NgModule({
  declarations: [
    AppComponent,ErrorPageComponent, RegistrationComponent,AdminpanelComponent, LoaderComponent, PassowordStrengthBarComponent
  ],
  imports: [
    BrowserModule,ReactiveFormsModule,HttpClientModule,FormsModule, RouterModule.forRoot(routes)
  ],
  providers: [SubscribeServiService,RegisterServiService,ServiceListService,GetTransectionDataService,PracticesearchService,UploadCertificateService,DoctorsearchService,TokenService,GeneratePracticeidService,GetPracticeidService,CustomHttpInterceptorService,
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptorService, multi: true }
   ],
  bootstrap: [AppComponent],
  exports:[RouterModule]
})
export class AppModule { }

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: true,
  /*apInfo:{
    apiUrl:'https://api-qa.cgmsa.co.za/',
    authUrl:'https://am-mgt-qa.cgmsa.co.za/oauth2/',
    Key:'m0IX8AjplxIZ0m8I2iT6RdL6QT8a',
    AuthKey:'m0IX8AjplxIZ0m8I2iT6RdL6QT8a',
    Secret:'bqEyZGUdG0LtmtB6VBrN5gf4zysa',
    AuthSecret:'bqEyZGUdG0LtmtB6VBrN5gf4zysa',
    callBack:'https://test-subscriptions.cgmsa.co.za/admin',
    logoutRedirect:'https://test-subscriptions.cgmsa.co.za/',
	versionVar: 'V2.0',
	doctorSearchVersion: 'V1.0',
	practiceSearchVersion: 'V1.0'
  }*/

  apInfo:{
    apiUrl:'https://api-qa.cgmsa.co.za/',
    authUrl:'https://am-mgt-qa.cgmsa.co.za/oauth2/',
    Key:'m0IX8AjplxIZ0m8I2iT6RdL6QT8a',
    AuthKey:'m0IX8AjplxIZ0m8I2iT6RdL6QT8a',
    Secret:'bqEyZGUdG0LtmtB6VBrN5gf4zysa',
    AuthSecret:'bqEyZGUdG0LtmtB6VBrN5gf4zysa',
    callBack:'https://test-subscriptions.cgmsa.co.za/admin',
    logoutRedirect:'https://test-subscriptions.cgmsa.co.za/',
	versionVar: 'V2.0',
	doctorSearchVersion: 'V1.0',
	practiceSearchVersion: 'V1.0'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthRegistrationService {
constructor(private _http:HttpClient){}
  callToken(){
    let params = new URLSearchParams();   
    params.append('grant_type','client_credentials');
    let headers = 
      new HttpHeaders({'Content-type': 'application/x-www-form-urlencoded; charset=utf-8','Authorization': 'Basic YnV2cjFGcjhmZjlmVmtjd2YzanJhOFIwNDQ0YTpMSGI4dXhyUmxEa2NicXVkZFJsbmhJQ0NMUjhh'});
      return this._http.post(environment.apInfo.authUrl+'Authtoken', 
        params.toString(), { headers: headers });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class SubscribeServiService {

  constructor(private http: HttpClient) { }
  getSubscriptionService(practiceNumber,doctorNumber,token) {
    let URL;
    let headers = 
    new HttpHeaders({'Authorization':'Bearer '+token});
    if(doctorNumber == 0 || doctorNumber == '0' || doctorNumber == '')
    {
      console.log("in else" +doctorNumber)
      URL = environment.apInfo.apiUrl+'registrations/'+environment.apInfo.versionVar+'/subscription/'+practiceNumber+'?doctorNumber=unknown';
     }else{
       
      console.log("in If" +doctorNumber)
      URL = environment.apInfo.apiUrl+'registrations/'+environment.apInfo.versionVar+'/subscription/'+practiceNumber+'?doctorNumber='+doctorNumber;
    
      }
    return this.http.get<void>(URL,{headers:headers});
  
    
    }
}
